@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply bg-primary text-white font-normal py-1 px-4 rounded-lg text-sm;
}

.btn:hover {
  @apply bg-pink-600;
  transition: background-color 0.2s ease-in-out;
}

.navbar a {
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #ff1cec;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.hero h1 {
  animation: slideInFromLeft 0.5s ease-out;
}

.hero button {
  animation: slideInFromLeft 0.5s ease-out;
  animation-delay: 0.3s; /* Delay button animation */
}

.product {
  transition: transform 0.3s ease;
}

.product:hover {
  transform: translateY(-10px);
}
